import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
	Title
} from '../../../components/ui'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {
	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render() {
		return (
			<Layout>
				{/* Intro section */}
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<Title as="h2" fontSize={'35px'} style={{ marginTop: '1em' }}>The 1, 2, 3's of Account types and Donation Types</Title><br/>

						<Paragraph>
						    With so many account types, and donation options, it can be confusing. There are checking accounts, credit cards, linked accounts, and donation accounts, each serving a unqiue purpose. Fundamentally, we break accounts into three groups,
							<strong> Depository accounts, Trackable accounts, </strong> and <strong>16-digit credit card numbers </strong> and each one has unqiue features that serve a purpose. Depository accounts are great for charging your donation; to keep bank fees down, we ask Flourish users to sign in to their bank that holds their checking account. This keeps our transaction fees well below the 3% that most banks charge to process a credit card, meaning we can offer a superior service to nonprofits at a fraction of the cost.
							<br/><br/>Our goal is for each user to have a completely set up account so we can maximize the donations flowing to our awesome clients. There are 3 ways for an account to be properly configured:

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>

							<hr style={{ margin: '1em 0' }}/>
							And that’s the definitive guide to engaging and securing the Millennial generation.
							<br/>
							<br/>
							Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
